import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f19e3fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "app-administrator-master-data-root" }
const _hoisted_4 = { class: "section-default" }
const _hoisted_5 = { class: "information" }
const _hoisted_6 = { class: "information__box-title" }
const _hoisted_7 = { class: "information__box-link" }
const _hoisted_8 = { class: "information__box-title" }
const _hoisted_9 = { class: "information__box-link" }
const _hoisted_10 = { class: "information__box-title" }
const _hoisted_11 = { class: "information__box-link" }
const _hoisted_12 = { class: "information__box-title" }
const _hoisted_13 = { class: "information__box-link" }
const _hoisted_14 = { class: "information__box-title" }
const _hoisted_15 = { class: "information__box-link" }
const _hoisted_16 = { class: "information__box-title" }
const _hoisted_17 = { class: "information__box-link" }
const _hoisted_18 = { class: "information__box-title" }
const _hoisted_19 = { class: "information__box-link" }
const _hoisted_20 = { class: "information__box-title" }
const _hoisted_21 = { class: "information__box-link" }
const _hoisted_22 = { class: "information__box-title" }
const _hoisted_23 = { class: "information__box-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdministratorNavigationVue = _resolveComponent("AdministratorNavigationVue")!
  const _component_Right = _resolveComponent("Right")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdministratorNavigationVue),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataBanksIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translation.banks), 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataPartnersRootIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translation.partners), 1),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataArticlesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translation.articles), 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataPlaceOfCostsIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translation.placeOfCosts), 1),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataCountiesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translation.counties), 1),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataPlacesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translation.places), 1),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataUnitOfMeasuresIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.translation.unitOfMeasures), 1),
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataPaymentTypesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.translation.paymentTypes), 1),
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorMasterDataInvoiceTypesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translation.invoiceTypes), 1),
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}